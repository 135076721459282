import useIteration from "@/composables/useIteration";
import { StepList } from "@/views/product/demandPool/index.vue";
import { ElMessage } from "element-plus";
import { onUnmounted, reactive, ref } from "vue";

/**
 *
 * @param e event对象
 * @returns boolean
 */
export function hasClassName(e: DragEvent & { target: HTMLElement }, className: string): boolean {
  return e.target.className.includes(className) ? true : false;
}

/**
 *
 * @param e event对象
 * @param property  css的属性key
 * @param style 实际设置的样式
 */
export function setDragStyle(e: DragEvent & { target: HTMLElement }, property: string, style: string) {
  (e.target.style as any)[property] = style;
}
/**
 *
 * @param e event对象
 * @param property  css的属性key
 * @param style 实际重置的样式
 */
export function resetDragStyle(e: DragEvent & { target: HTMLElement }, property: string, style: string) {
  (e.target.style as any)[property] = style;
}

export enum DragStyle {
  // 拖拽到正方形添加下划线样式
  setDragRectStyle = "2px solid rgba(0,0,0,.4)",
  // 添加整个容器边框的下划线
  setWrapperBorderStyle = "1px solid rgba(0,0,0,.4)",
  unset = "unset",
  activeColor = "rgba(230, 252, 247, 1)",
  disableColor = "rgba(225, 239, 251, 1)"
}

interface Draggable {
  startColumnIndex: number;
  startBlockIndex: number;
}

export default function useDraggable() {
  const draggable: Draggable = {
    startColumnIndex: 0,
    startBlockIndex: 0
  };
  let timer: ReturnType<typeof setTimeout>;
  // 监听滚动
  let lastMouseoverDom: HTMLElement;
  // 获取当前页面所有scroll的dom，绑定鼠标移入事件
  const columnsWrapperRef = ref();
  let columnDom: Array<HTMLElement>;
  const { useUpdateIterationStatus } = useIteration();

  onUnmounted(() => {
    Array.isArray(columnDom) &&
      columnDom.forEach((dom: HTMLElement) => {
        dom?.removeEventListener("mouseover", () => null, false);
      });
  });
  // 迭代状态：0待开始，2开发中，3联调中，4测试中，6已发版
  const baseData = reactive<Array<StepList>>([
    {
      titleColor: "#fff",
      titleName: "未开始",
      status: 0,
      listData: [],
      isDisable: false,
      isShowLoadingMore: false,
      hasData: true,
      page_index: 1,
      page_size: 10,
      bgColor: "#e6fcf7",
      count: 0
    },
    {
      titleColor: "#fff",
      titleName: "开发中",
      status: [2, 1], // 这里2,1这样排序是因为每个tab下拉加载更多的时候，只传status第一个参数给到后端
      listData: [],
      isDisable: false,
      isShowLoadingMore: false,
      hasData: true,
      page_index: 1,
      bgColor: "#ff4b4b1a",
      page_size: 10,
      count: 0
    },
    {
      titleColor: "#fff",
      titleName: "联调中",
      status: [3, 8],
      listData: [],
      isDisable: false,
      isShowLoadingMore: false,
      hasData: true,
      page_index: 1,
      bgColor: "#e6fcf7",
      page_size: 10,
      count: 0
    },
    {
      titleColor: "#d3dfdd",
      titleName: "测试中",
      status: [4, 5],
      listData: [],
      isDisable: true,
      isShowLoadingMore: false,
      hasData: true,
      page_index: 1,
      bgColor: "#e6fcf7",
      page_size: 10,
      count: 0
    },
    {
      titleColor: "#d3dfdd",
      titleName: "已发布",
      status: 6,
      listData: [],
      isDisable: true,
      isShowLoadingMore: false,
      hasData: true,
      page_index: 1,
      bgColor: "#e6fcf7",
      page_size: 10,
      count: 0
    }
  ]);

  const bindScrollDom = () => {
    const scrolBar = columnsWrapperRef.value?.getElementsByClassName("demand-list-cloumn");
    if (scrolBar) {
      columnDom = [...scrolBar];
      columnDom.forEach((dom: HTMLElement) => {
        dom.addEventListener("mouseover", () => {
          lastMouseoverDom = dom;
        });
      });
    }
  };
  const sortData = (lists: Array<Record<string, any>>): void => {
    baseData.forEach((v) => {
      v.listData = [];
      lists?.forEach((list) => {
        if (Array.isArray(v.status) ? v.status.includes(list.status) : v.status === list.status) {
          v.listData.push(list);
        }
      });
      v.isShowLoadingMore = v.listData.length > 9 ? true : false;
      v.hasData = true;
      v.page_index = 1;
      v.page_size = 10;
    });
  };

  const handleDragenter = (e: DragEvent & { target: HTMLElement }, parentIndex: number, index?: number) => {
    const { startColumnIndex, startBlockIndex } = draggable;
    // 如果是自身不需要添加下划线
    if (parentIndex === startColumnIndex && index === startBlockIndex) {
      return;
    }
    hasClassName(e, "block") && setDragStyle(e, "borderBottom", DragStyle.setDragRectStyle);
    // 如果是li元素，给整个li添加border
    hasClassName(e, "el-scrollbar__wrap") && setDragStyle(e, "border", DragStyle.setWrapperBorderStyle);

    if (parentIndex === 3) {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        ElMessage({
          type: "info",
          message: "测试中、已发布迭代不可变更状态"
        });
      }, 1500);
    }
  };
  const handleDragleave = (e: DragEvent & { target: HTMLElement }) => {
    resetDragStyle(e, "borderBottom", DragStyle.unset);
    hasClassName(e, "el-scrollbar__wrap") && resetDragStyle(e, "border", DragStyle.unset);
  };
  const handleDragstart = (columnIndex: number, blockIndex: number) => {
    draggable.startColumnIndex = columnIndex;
    draggable.startBlockIndex = blockIndex;
  };
  const handleDrop = async (
    e: DragEvent & { target: HTMLElement },
    columnIndex: number,
    cb?: (params: { status: number; page_index?: number; type: "modifyStatus" }) => void
  ) => {
    const { startColumnIndex, startBlockIndex } = draggable;

    if (columnIndex >= 3) {
      hasClassName(e, "block") && resetDragStyle(e, "borderBottom", DragStyle.unset);
      resetDragStyle(e, "border", DragStyle.unset);
      return;
    }

    if (startColumnIndex === columnIndex) {
      hasClassName(e, "block") && resetDragStyle(e, "borderBottom", DragStyle.unset);
      return;
    }
    const listData = baseData[startColumnIndex].listData[startBlockIndex];

    if (listData) {
      const params = {
        iteration_id: listData.id,
        // @ts-ignore
        status: Array.isArray(baseData[columnIndex].status) ? baseData[columnIndex].status[0] : baseData[columnIndex].status
      };

      const isSucc = await useUpdateIterationStatus(params);
      if (isSucc) {
        ElMessage.success("操作成功");
        typeof cb === "function" && cb({ status: params.status, page_index: listData.page_index, type: "modifyStatus" });
      }
    }
  };

  const handleScroll = async (
    params: Record<string, any>,
    cb?: (params?: { status: number; page_index: number }, cb?: (resp: Record<string, any>) => void) => void
  ) => {
    if (params.scrollTop === 0) return; // 切换项目异常处理
    if (!lastMouseoverDom) return;
    // 计算是否滚动到底部
    const scrollTop = (lastMouseoverDom.getElementsByClassName("demand-detail-show")[0] as HTMLElement)?.scrollTop;
    // 获取当前容器div高度
    const wrapperHeight = lastMouseoverDom.offsetHeight;
    // // 获取内容的总体高度
    const scrollViewHeight = (lastMouseoverDom.getElementsByClassName("demand-detail-show")[0] as HTMLElement).scrollHeight;
    const columnIndex = parseInt(lastMouseoverDom.getAttribute("data-index")!);
    const curItem = baseData[columnIndex];
    if (scrollTop + wrapperHeight >= scrollViewHeight) {
      // 滚动到底部，展示箭头
      if (curItem.hasData) {
        // 当前列数据小于10，不出现箭头
        if (curItem.listData.length < 10) {
          curItem.isShowLoadingMore = false;
          return;
        }
        // hasData存在表示还有数据，再展示箭头
        curItem.isShowLoadingMore = true;
      }
    } else {
      curItem.isShowLoadingMore = false;
    }

    if (curItem.isShowLoadingMore && typeof cb === "function" && curItem.hasData) {
      const params = {
        status: Array.isArray(curItem.status) ? curItem.status[0] : curItem.status,
        page_index: curItem.page_index + 1,
        type: "turnPage"
      };
      cb(params, (resp: any) => {
        if (resp && Array.isArray(resp)) {
          if (resp.length) {
            curItem.listData.push(...resp);
            curItem.page_index++;
          } else {
            curItem.hasData = false;
          }
        }
      });
    }
  };

  return {
    columnsWrapperRef,
    bindScrollDom,
    baseData,
    sortData,
    handleScroll,
    handleDragenter,
    handleDragleave,
    handleDrop,
    handleDragstart
  };
}
